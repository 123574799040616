import React from "react";
import { Link } from "react-router-dom";
import { TbPhone } from "react-icons/tb";
import { TbLocation } from "react-icons/tb";
import { BsEnvelope } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import Logo from "../assets/images/logo/logo.png";
import {
  GrFacebookOption,
  GrInstagram,
  GrTwitter,
  GrYoutube,
} from "react-icons/gr";
import { BsWhatsapp } from "react-icons/bs";

function Footer() {
  return (
    <>
      <footer className="sec-pad">
        <div className="right-side-fixed-container">
          <ul className="social-media-list">
            <li>
              <a
                href="https://wa.me/+911111111111"
                target="_blank"
                className="footer-wp"
              >
                <span>
                  <BsWhatsapp />
                </span>{" "}
                WhatsApp Us
              </a>
            </li>
            <li>
              <a href="" target="_blank" className="footer-fb">
                <span>
                  <GrFacebookOption />
                </span>{" "}
                Follow Us
              </a>
            </li>
            <li>
              <a href="" target="_blank" className="footer-insta">
                <span>
                  <GrInstagram />
                </span>{" "}
                Follow Us
              </a>
            </li>
            <li>
              <a href="" target="_blank" className="footer-twitter">
                <span>
                  <GrTwitter />
                </span>{" "}
                Follow Us
              </a>
            </li>
            <li>
              <a href="" target="_blank" className="footer-youtube">
                <span>
                  <GrYoutube />
                </span>{" "}
                Subscribe Us
              </a>
            </li>
            <li>
              <a href="" target="_blank" className="footer-linkedIn">
                <span>
                  <FaLinkedinIn />
                </span>{" "}
                Connect Us
              </a>
            </li>
          </ul>
        </div>
        <div className="left-side-fixed-container">
          <ul>
            <li>
              Fair Play <span>F</span>
            </li>
            <li>
              One Team <span>O</span>
            </li>
            <li>
              Sportsmanship <span>S</span>
            </li>
            <li>
              Trust <span>T</span>
            </li>
            <li>
              Excellence in Execution <span>E</span>
            </li>
            <li>
              Respect <span>R</span>
            </li>
          </ul>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="footer-logo-wrapper">
                <img src={Logo} alt="logo" />
                <div className="footer-social-media-wrapper">
                  <ul>
                    <li>
                      <a href="" className="footer-fb">
                        <GrFacebookOption />
                      </a>
                    </li>
                    <li>
                      <a href="" className="footer-insta">
                        <GrInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="" className="footer-twitter">
                        <GrTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="" className="footer-youtube">
                        <GrYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-column-wrapper">
                <h2>About Clubs</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About US</Link>
                  </li>
                  <li>
                    <Link to="/">Academy Program</Link>
                  </li>
                  <li>
                    <Link to="/">Latest News</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-column-wrapper">
                <h2>Teams Info</h2>
                <ul>
                  <li>
                    <Link to="/">Player Profile</Link>
                  </li>
                  <li>
                    <Link to="/about">Fixtures</Link>
                  </li>
                  <li>
                    <Link to="/">Tournament</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-contact">
                <h2>Contact Us</h2>
                <ul>
                  <li>
                    <TbPhone /> <a href="tel:9920614314">+91 9920614314</a>
                  </li>
                  <li>
                    <BsEnvelope />{" "}
                    <a href="mailto:admin@snigmaypunefc.com" target="_blank">
                      admin@snigmaypunefc.com
                    </a>
                  </li>
                  <li>
                    <TbLocation /> Sportszy Arena, <br />
                    Opposite Sharda English High School , <br />
                    Krushna Colony, Rahatani, Pune 411017
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-strip">
          <p className="text-center mb-0">
            &copy; 2023 Snigmay FC. All rights reserved. &nbsp;&nbsp;Designed
            By-{" "}
            <a href="https://mysportsclub.co.in/" target="_blank">
              MySportsClub
            </a>
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
